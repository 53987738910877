import errors from "@/enums/errors";

const getResponseErrorMessage = (response, namespace) => {
  const { status, message } = response;

  if (namespace && getDisplayMessage(`${namespace}/${status}`)) {
    return getDisplayMessage(`${namespace}/${status}`);
  }

  if (status === 401) {
    return "You are not authorised to perform this action. Please refresh the page and try again, or sign out and back in.";
  }

  if (status === 413) {
    return "The content is too large.";
  }

  return message || "An error occurred. Please refresh the page and try again.";
};

/**
 * Firebase callables return an error with nothing useful in the message.
 */
const isFirebaseCallableError = (exception) => {
  return exception.name === "FirebaseError";
};

const toDisplayMessage = (exception, namespace) => {
  if (isFirebaseCallableError(exception)) {
    const firebaseToHttpStatus = {
      "functions/not-found": 404,
    };

    return getResponseErrorMessage(
      {
        status: firebaseToHttpStatus[exception?.code] || 500,
        message: "An error occurred.",
      },
      namespace
    );
  }

  if (exception.response) {
    return getResponseErrorMessage(exception.response, namespace);
  }

  return exception.message;
};

/**
 * Accessor to the enums/errors object without exposing it.
 */
const getDisplayMessage = (namespace) => {
  return errors[namespace];
};

export default function useErrors() {
  return {
    getDisplayMessage,
    toDisplayMessage,
  };
}

export const APP_TITLE = process.env.VUE_APP_TITLE;
export const APP_URL = process.env.VUE_APP_URL;

// Possibly deprecated
export const NODE_ENV = process.env.NODE_ENV;
export const IS_PRODUCTION =
  process.env.VUE_APP_FIREBASE_PROJECT_ID.toLowerCase().includes("production");

// This is generated by Webpack using package.json
export const APP_VERSION = process.env.VERSION;

// Firebase
export const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.VUE_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.VUE_APP_FIREBASE_APP_ID;

// Moverly API
export const MOVEREADY_FILE_API_URL =
  process.env.VUE_APP_MOVEREADY_FILE_API_URL;
export const MOVEREADY_PDTF_API_URL =
  process.env.VUE_APP_MOVEREADY_PDTF_API_URL;
export const MOVEREADY_FINANCES_API_URL =
  process.env.VUE_APP_MOVEREADY_FINANCES_API_URL;
export const MOVEREADY_ORGANISATION_API_URL =
  process.env.VUE_APP_MOVEREADY_ORGANISATION_API_URL;
export const MOVEREADY_USER_API_URL =
  process.env.VUE_APP_MOVEREADY_USER_API_URL;
export const MOVEREADY_INTEGRATION_API_URL =
  process.env.VUE_APP_MOVEREADY_INTEGRATION_API_URL;
export const MOVEREADY_ADMIN_API_URL =
  process.env.VUE_APP_MOVEREADY_ADMIN_API_URL;
export const MOVEREADY_AML_API_URL = process.env.VUE_APP_MOVEREADY_AML_API_URL;

// Moverly mock/canned data for local dev
export const MOVERLY_ENABLE_TITLE_SEARCH_MOCK_DATA =
  process.env.VUE_APP_MOVERLY_ENABLE_TITLE_SEARCH_MOCK_DATA === "true";

// Google Maps (depreacted?)
export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

// Mapbox (agent 3D title plan view)
export const MAPBOX_ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
// Not accurately named - this is an OS public key used to get the OS mapes in the 3D view.
export const MAPBOX_API_KEY = process.env.VUE_APP_MAPBOX_API_KEY;
export const MAPBOX_API_URL = process.env.VUE_APP_MAPBOX_API_URL;

// Firebase
export const FIREBASE_AUTH_USE_EMULATOR =
  process.env.VUE_APP_FIREBASE_AUTH_USE_EMULATOR === "true";
export const FIREBASE_FUNCTIONS_USE_EMULATOR =
  process.env.VUE_APP_FIREBASE_FUNCTIONS_USE_EMULATOR === "true";
export const FIRESTORE_USE_EMULATOR =
  process.env.VUE_APP_FIRESTORE_USE_EMULATOR === "true";
export const FIREBASE_APP_CHECK_SITE_KEY =
  process.env.VUE_APP_FIREBASE_APP_CHECK_SITE_KEY;
export const FIREBASE_DISABLE_APP_CHECK =
  process.env.VUE_APP_FIREBASE_DISABLE_APP_CHECK === "true";
export const FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS =
  process.env.VUE_APP_FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS === "true";

// Google Tag Manager
export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const GTM_AUTH = process.env.VUE_APP_GTM_AUTH;
export const GTM_PREVIEW = process.env.VUE_APP_GTM_PREVIEW;
export const GTM_DISABLE_PUSH_USER_DATA =
  process.env.VUE_APP_GTM_DISABLE_PUSH_USER_DATA;

export const VUE_APP_REVIEW_ENV = process.env.VUE_APP_REVIEW_ENV;

// Loqate (address search)
export const LOQATE_API_KEY = process.env.VUE_APP_LOQATE_API_KEY;

// Honeybadger (error logging)
export const HONEYBADGER_API_KEY = process.env.VUE_APP_HONEYBADGER_API_KEY;
export const HONEYBADGER_ENV = process.env.VUE_APP_HONEYBADGER_ENV;
export const HONEYBADGER_DISABLE_LOGGING =
  process.env.VUE_APP_HONEYBADGER_DISABLE_LOGGING;

// Feature flags
export const FEATURE_UPFRONT_SEARCHES =
  process.env.VUE_APP_FEATURE_UPFRONT_SEARCHES === "true";

// Stripe (card payment)
export const STRIPE_API_KEY = process.env.VUE_APP_STRIPE_API_KEY;

// Reapit
export const REAPIT_CLIENT_ID = process.env.VUE_APP_REAPIT_CLIENT_ID;
export const REAPIT_CONNECT_APPLICATION_TIMEOUT =
  process.env.VUE_APP_REAPIT_CONNECT_APPLICATION_TIMEOUT;
export const REAPIT_CONNECT_USER_POOL_ID =
  process.env.VUE_APP_REAPIT_CONNECT_USER_POOL_ID;
export const REAPIT_CONNECT_LOGIN_REDIRECT_PATH =
  process.env.VUE_APP_REAPIT_CONNECT_LOGIN_REDIRECT_PATH;
export const REAPIT_CONNECT_LOGOUT_REDIRECT_PATH =
  process.env.VUE_APP_REAPIT_CONNECT_LOGOUT_REDIRECT_PATH;
export const REAPIT_CONNECT_OAUTH_URL =
  process.env.VUE_APP_REAPIT_CONNECT_OAUTH_URL;
export const REAPIT_PROPERTY_URL = process.env.VUE_APP_REAPIT_PROPERTY_URL;

// Alto
export const ALTO_PROPERTY_URL = process.env.VUE_APP_ALTO_PROPERTY_URL;
export const ALTO_APP_URL = process.env.VUE_APP_ALTO_APP_URL;

// Front (proxied by our back-end, hence no API key or token)
export const FRONT_API_BASE_URL = process.env.VUE_APP_FRONT_API_BASE_URL;
export const FRONT_CUSTOM_CHANNEL_UID =
  process.env.VUE_APP_FRONT_CUSTOM_CHANNEL_UID;
export const FRONT_EMAIL_INBOX_UID = process.env.VUE_APP_FRONT_EMAIL_INBOX_UID;

// AI Constants - these should be synchronised with the back-end file /functions/shared_utilities/ai/aiConstants.js
// or perhaps could come from an internal shared npm package one day (see https://moverly.slack.com/archives/C02GLB7BULC/p1731074018932529)
export const AI_CONSTANTS = {
  REPORT_FLAG_ITEMS_SOURCE: "omniReporter",
  REPORT_RISK_ITEMS_FILE_SLUG: "REPORT_ITEMS_CSV",
};

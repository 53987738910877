import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_FINANCES_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import Honeybadger from "@honeybadger-io/js";
import useChecks from "@/composables/flags/checks";
import { collectorState, checkSeverity } from "@/enums/checks";

const { getAccessToken } = useAuth();
const { fetchFlagByName, checks } = useChecks();

const isFetchingConsumerCharges = ref(false);
const consumerChargesErrorMessage = ref("");
const consumerCharges = ref(null);
const isCreatingConsumerChargesPaymentIntent = ref(false);
const consumerChargesPaymentErrorMessage = ref("");
const paymentIntentId = ref(null);
const paymentIntent = ref(null);
const idempotencyKey = ref(null);

// TODO: Handle 403 forbidden
const fetchConsumerCharges = async (transactionId, userId) => {
  try {
    isFetchingConsumerCharges.value = true;
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = await getAccessToken();
    const fetchConsumerChargesResponse = await axios.get(
      `${MOVEREADY_FINANCES_API_URL}/payments/consumer-charges`,
      {
        params: {
          userId,
          transactionId,
        },
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    consumerCharges.value = fetchConsumerChargesResponse.data;
  } catch (ex) {
    consumerChargesErrorMessage.value = ex?.response?.data?.error || ex.message;
    consumerCharges.value = null;
  } finally {
    isFetchingConsumerCharges.value = false;
  }

  return consumerCharges.value;
};

const hasConsumerCharges = computed(() => {
  return consumerCharges.value?.items?.length > 0;
});

const createConsumerChargesPaymentIntent = async (transactionId, userId) => {
  try {
    isCreatingConsumerChargesPaymentIntent.value = true;
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = await getAccessToken();

    const createPaymentIntentResponse = await axios.post(
      `${MOVEREADY_FINANCES_API_URL}/payments/consumer-charges`,
      { userId, transactionId },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    paymentIntentId.value = createPaymentIntentResponse.data.paymentId;
    paymentIntent.value = createPaymentIntentResponse.data.paymentIntent;
    // idempotencyKey.value = createPaymentIntentResponse.data.idempotencyKey;
    // Using the idempotency key from the response will cause an error in Stripe. It doesn't like the same one used to create and confirm the payment intent.
    idempotencyKey.value = new Date().getTime().toString();

    return paymentIntent.value;
  } catch (ex) {
    consumerChargesPaymentErrorMessage.value = ex.message;
    Honeybadger.notify(ex, {
      name: "createConsumerChargesPaymentIntent",
      message: `Failed to create a consumer charges payment intent for ${transactionId} and userId ${userId}.`,
      params: {
        transactionId,
        userId,
      },
    });
    throw ex;
  } finally {
    isCreatingConsumerChargesPaymentIntent.value = false;
  }
};

const confirmConsumerChargesPayment = async ({
  transactionId,
  paymentId,
  userId,
  paymentMethod = "pm_card_visa",
  returnUrl,
  idempotencyKey,
}) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = await getAccessToken();
    const response = await axios.post(
      `${MOVEREADY_FINANCES_API_URL}/payments/consumer-charges/${paymentId}/confirm`,
      {
        transactionId,
        paymentId,
        userId,
        paymentMethod,
        returnUrl,
        idempotencyKey, // Stripe requires a unique idempotency key for each request
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    return response.data;
  } catch (ex) {
    consumerChargesPaymentErrorMessage.value = ex.message;
    Honeybadger.notify(ex, {
      name: "confirmConsumerChargesPayment",
      message: `Failed to confirm consumer charges payment for ${transactionId} and userId ${userId}.`,
      params: {
        transactionId,
        userId,
        paymentId,
      },
    });
    throw ex;
  }
};

const consumerChargePurchaseOrderFlags = computed(() => {
  const consumerChargeItems = consumerCharges.value?.items || [];
  const flags = consumerChargeItems
    .map((charge) => {
      return fetchFlagByName(
        `consumerCharge-${charge.role.toLowerCase()}-${charge.productCode}`,
        checks.value
      );
    })
    .filter((flag) => flag !== null);

  return flags;
});

// In case the user refreshes the page, we need to check if the payment is pending and not display the Stripe form.
const isConsumerChargesPaymentPending = computed(() => {
  const consumerChargeItems = consumerCharges.value?.items || [];

  return (
    consumerChargePurchaseOrderFlags.value.every(
      (flag) => flag?.collectorState !== collectorState.SETTLED
    ) && consumerChargeItems.length > 0
  );
});

const isConsumerChargesPaymentSuccess = computed(() => {
  const consumerChargeItems = consumerCharges.value?.items || [];
  return (
    consumerChargePurchaseOrderFlags.value.every(
      (flag) =>
        flag?.collectorState === collectorState.SETTLED &&
        flag?.severity === checkSeverity.SUCCESS
    ) &&
    consumerChargeItems.length === consumerChargePurchaseOrderFlags.value.length
  );
});

export default function useConsumerCharges() {
  return {
    confirmConsumerChargesPayment,
    consumerChargePurchaseOrderFlags,
    consumerCharges,
    consumerChargesErrorMessage,
    consumerChargesPaymentErrorMessage,
    createConsumerChargesPaymentIntent,
    fetchConsumerCharges,
    isConsumerChargesPaymentPending,
    hasConsumerCharges,
    idempotencyKey,
    isConsumerChargesPaymentSuccess,
    isCreatingConsumerChargesPaymentIntent,
    isFetchingConsumerCharges,
    paymentIntent,
    paymentIntentId,
  };
}
